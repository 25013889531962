<template>
  <div class="px-8 mt-4">
    <v-dialog v-model="dialogItemType" persistent width="670px">
      <v-card>
        <v-card-title>
          <span class="subheader"> Choose an Item Type:</span>
          <v-spacer></v-spacer>
          <v-btn icon dark color="secondary2" @click="clickCloseMainDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        
          <v-card-text>
            <v-form @submit.prevent ref="formItemType">
                  <v-chip-group
                    v-model="formData.ItemTypeID"
                    active-class="header white--text"
                    class="ma-2"
                  >
                    <v-chip v-for="rt in item_types" :value="rt.id" :key="'rt-' + rt.id" large class="px-5 chip-width">
                      <v-icon left>
                        {{rt.icon}}
                      </v-icon>
                        {{rt.description}}
                    </v-chip>
                  </v-chip-group>
                  </v-form>
          </v-card-text>
        <v-card-actions class="pb-3">
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" outlined @click="clickCloseMainDialog()"> Cancel </v-btn>
          <v-btn
            color="header"
            class="white--text"
            @click="saveItemType()"
            :loading="isLoading"
            :disabled="formData.ItemTypeID == null"
          >
          <v-icon class="mr-1">mdi-checkbox-marked-outline</v-icon>
            Select
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogModel"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="poModal"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="green darken-2" max-height="65">
          <v-toolbar-title>Dispose Items</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="clickCloseMainDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text style="max-height: 900px">
          <div>
            <v-row class="mt-4">
              <v-col cols="12" class="d-flex justify-center">
                <v-subheader class="font-weight-bold text-h6 text-center">
                  DISPOSAL OF UNSERVICEABLE ITEMS
                </v-subheader>
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col cols="12" class="py-0 my-0 mt-8">
              <v-toolbar flat dense>
                    <v-spacer></v-spacer>
                    <v-autocomplete
                    v-model="formData.ItemTypeID"
                    @change="getItems()"
                    item-text="description"
                    item-value="id"
                    :rules="[formRules.required]"
                    :items="item_types"
                    class="mr-4"
                    label="Select an Item Type"
                    style="max-width: 265px"
                    color="header"
                    hide-details
                  >
                  </v-autocomplete>
                    <v-btn
                      color="red darken-2"
                      class="white--text"
                      :disabled="formData.Lists.length == 0"
                      @click="dispose()"
                    >
                      <v-icon class="mr-1">mdi-delete</v-icon>
                      DISPOSE
                    </v-btn>
                  </v-toolbar>
            </v-col>
            <v-col lg="12" class="">
              <v-data-table
                v-model="formData.Lists"
                :headers="headers"
                :items="itemList"
                item-key="ItemUniqueID"
                class="elevation-1 mt-4"
                :search="searchText"
                :expanded.sync="expanded"
                :loading="isLoading"
                :show-expand="false"
                show-select
                @toggle-select-all="selectAllToggle"
                :items-per-page="-1"
                :footer-props="{
                  'items-per-page-options': [-1]
                }"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <span class="green--text text--darken-2"
                      >List Of Unserviceable Items</span
                    >
                    <v-spacer></v-spacer>
                    <v-text-field
                    v-model="searchText"
                    append-icon="mdi-magnify"
                    label="Search Item here..."
                    style="max-width: 300px"
                    color="header"
                    hide-details
                  >
                  </v-text-field>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
                  <v-simple-checkbox
                    :value="isSelected"
                    :ripple="false"
                    :readonly="item.disabled"
                    :disabled="item.disabled"
                    @input="select($event)"
                  ></v-simple-checkbox>
                </template>
                <template v-slot:[`item.UnitCost`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost) }}</span>
                </template>
                <template v-slot:[`item.Qty`]="{ item }">
                    {{ formatQty(item.Qty) }}
                </template>
                <template v-slot:[`item.Amount`]="{ item }">
                  <span class="text-no-wrap">₱ {{formatPrice(parseFloat(item.UnitCost) * parseFloat(item.Qty))}}</span>
                </template>
                <template v-slot:[`item.Remarks`]="{ item }">
                  <span :class="item.RemarksID == 1 ? 'green--text' : item.RemarksID == 2 ? 'red--text' : ''">{{item.Remarks}}</span>
                </template>
                <template v-slot:[`item.TransferQty`]="{ item }">
                  {{formatQty(item.TransferQty)}}
                </template>
                <template v-slot:[`item.DisposeQty`]="{ item }">
                  {{formatQty(item.DisposeQty)}}
                </template>
                <!-- <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <div class="my-5">
                    <v-row v-for="(mr, index) in item.MRData" :key="index">
                      <v-col cols="12" class="px-2">
                        <v-row>
                          <v-col cols="12" sm="3">
                            <strong>Persons Accountable: </strong>
                          </v-col>
                          <v-col cols="12" sm="9">
                            {{getPANames(mr.UserID)}}
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="4" class="px-2">
                         <strong>Brand: </strong> {{mr.Brand || 'Not applicable'}}
                      </v-col>
                      <v-col cols="12" sm="4" class="px-2">
                        <strong>Brand: </strong> {{mr.Model || 'Not applicable'}}
                      </v-col>
                      <v-col cols="12" sm="4" class="px-2">
                        <strong>Serial No.: </strong> {{mr.SerialNo || 'Not applicable'}}
                      </v-col>
                      <v-col v-if="item.MRData.length > index + 1 "><v-divider></v-divider></v-col>
                    </v-row>
                    </div>
                  </td>
                </template> -->
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-dialog v-model="dialogProcess" max-width="1250px" scrollable>
        <v-card>
          <v-card-title class="headline pt-5">
            <span>Dispose Items ({{formData.Lists.length}})</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogProcess = false"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-card-text style="max-height: 900px">
          <v-form @submit.prevent ref="DisposeFormRef">
          <v-container>
            <v-row>
              <v-col cols="12">
              <v-data-table
                :headers="headers2"
                :items="formData.Lists"
                class="elevation-1"
                :expanded.sync="expanded2"
                :show-expand="false"
              >
              <template v-slot:top>
                  <v-toolbar flat>
                    <span class="green--text text--darken-2">List Of Selected Items</span>
                  </v-toolbar>
              </template>
                <template v-slot:[`item.UnitCost`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost) }}</span>
                </template>
                <template v-slot:[`item.Qty`]="{ item }">
                  {{ formatQty(item.Qty) }}
                </template>
                <template v-slot:[`item.allowableQty`]="{ item }">
                  {{ formatQty(item.allowableQty) }}
                </template>
                <template v-slot:[`item.editableQty`]="{ item }">
                  <v-edit-dialog :return-value.sync="item.editableQty" large>
                    <span :style="parseFloat(item.allowableQty) < parseFloat(item.editableQty) || parseFloat(item.editableQty) <= 0 ? 'color:red' : 'color:black'">{{ formatQty(item.editableQty) }}</span>
                    <template v-slot:input>
                      <v-text-field v-model="item.editableQty" type="number" :min="1" :max="parseFloat(item.allowableQty)" :rules="[parseFloat(item.allowableQty) >= parseFloat(item.editableQty), parseFloat(item.editableQty) > 0]"> </v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <div class="my-5">
                      <v-autocomplete
                        v-model="item.MRData_selected"
                        label="Brand | Model | Serial No."
                        @change="changeSerialNo(item)"
                        :rules="[formRules.required, formRules.checkMRBalance(item.MRData_selected.length, item.editableQty)]"
                        outlined
                        dense
                        chips
                        hide-selected
                        :menu-props="{closeOnContentClick: true}"
                        :counter="item.editableQty" 
                        auto-select-first
                        small-chips
                        deletable-chips
                        multiple
                        color="header"
                        :items="item.MRData"
                        :item-text="getItemText"
                        :item-value="getItemValue"
                      ></v-autocomplete>
                    </div>
                  </td>
                </template>
              </v-data-table>
              </v-col>
            </v-row>
          </v-container>
          </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined @click="dialogProcess = false">Cancel</v-btn>
            <v-btn color="red darken-2" :loading="disposeLoading" class="white--text" @click="confirmDispose()"><v-icon>mdi-delete</v-icon> Dispose</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>
    
    <script>
export default {
  components:{
  },
  props: {
    display: null,
  },
  data: () => ({
    dialog: false,
    searchText: '',
    users: [],
    users_temp: [],
    dialogItemType: false,
    dialogModel: false,
    itemList: [],
    formData: {
      Lists: [],
      ItemTypeID: null,
    },
    disabledCount: 0,
    isLoading: false,
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
    headers: [
      {
        text: "Stock/ Property No.",
        align: "start",
        sortable: false,
        value: "StockPropertyNo",
      },
      {
        text: "RS CODE",
        align: "start",
        sortable: false,
        value: "RSCODE",
      },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        align: "center",
        sortable: false,
      },
      { text: "QTY", align:"center", value: "Qty", sortable: false },
      { text: "Unit Cost", align:"end", value: "UnitCost", sortable: false },
      { text: "Amount", align:"end", value: "Amount", sortable: false },
      { text: "Qty(Disposed)", align:"center", value: "DisposeQty", sortable: false },

    ],
    expanded: [],
    singleExpand: true,
    expanded2: [],
    dialogProcess: false,
    renewLoading: false,
    dispenseTypes: [],
    DispenseTypeID: null,
    process_type: null,
    UserID_temp: null,
    headers2: [
      {
        text: "Stock/ Property No.",
        align: "start",
        sortable: false,
        value: "StockPropertyNo",
      },
      {
        text: "RS CODE",
        align: "start",
        sortable: false,
        value: "RSCODE",
      },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        align: "center",
        sortable: false,
      },
      { text: "Unit Cost", align:"end", value: "UnitCost", sortable: false },
      { text: "Qty(Overall)", align:"center", value: "Qty", sortable: false },
      { text: "Qty(Allowable)", align:"center", value: "allowableQty", sortable: false },
      { text: "Qty(Editable)", align:"center", value: "editableQty", sortable: false },
    ],
    disposeLoading: false,
    item_types: [
      {id: 1, description: 'STOCKS', icon: 'mdi-file'},
      {id: 2, description: 'SEMI-EXPENDABLE PROPERTIES', icon: ' mdi-file-multiple'},
      {id: 3, description: 'PROPERTIES', icon: 'mdi-file-replace'},
    ],
  }),
  watch: {
    display: {
        handler(data){
            if(data == true){
                this.dialogItemType = true;
            }
        },
        deep: true
    },
  },
  created() {
    const self = this;
    this.itemList.map(item => {
      if (item.disabled) self.disabledCount += 1
    })
  },
  mounted() {
    
  },
  methods: {
    saveItemType(){
      this.dialogModel = true;
      this.getItems();
    },
    clickCloseMainDialog() {
      this.itemList = [];
      this.dialogModel = false;
      this.dialogItemType = false;
      this.eventHub.$emit("closeDisposeItems", false);
    },
    dispose(){
      this.dialogProcess = true;
    },
    getItems() {
      this.unpublishBtn = false;
      this.formData.Lists = [];
      this.axiosCall("/get/unserviceableItems/" + this.formData.ItemTypeID, "GET").then((res) => {
        res.data.data.forEach(item => {
          item.allowableQty = parseFloat(item.Qty) - parseFloat(item.DisposeQty);
          item.editableQty = item.allowableQty;
          item.MRData_selected = item.MRData;
          item.ReturnType = true;
          if(item.disabled == 1){
            item.disabled = true
          }
          else{
            item.disabled = false
          }
        });
        this.itemList = res.data.data;
        this.selectAllToggle({items: res.data.data, value: true})
        this.selectAllToggle({items: res.data.data, value: false})
        this.isLoading = false;
      });
    },
    confirmDispose(){
      if(this.$refs.DisposeFormRef.validate() && this.formData.Lists.length > 0){
        let lists = JSON.parse(JSON.stringify(this.formData.Lists))
        lists.forEach(item => {
          let mritem = [];
          item.MRData_selected.forEach(item2 => {
            mritem.push(item2.MRItemID);
          });
          item.MRData_selected = mritem;
        });
          let data = new FormData();
          data.append("List", JSON.stringify(lists));
          data.append("DisposeTypeID", this.formData.ItemTypeID);
          this.axiosCall("/disposal/add", "POST", data).then((res) => {
            if(res.data.status){
              this.dialogProcess = false
              this.getItems();
              this.fadeAwayMessage.show = true;
              this.fadeAwayMessage.type = "success";
              this.fadeAwayMessage.header = "System Message";
              this.fadeAwayMessage.message = "All selected Items were successfully disposed!";
            }
            else{
              this.fadeAwayMessage.show = true;
              this.fadeAwayMessage.type = "error";
              this.fadeAwayMessage.header = "System Message";
              this.fadeAwayMessage.message = "Something went wrong!";
            }
            });
      }
      else{
        this.fadeAwayMessage.show = true;
        this.fadeAwayMessage.type = "error";
        this.fadeAwayMessage.header = "System Message";
        this.fadeAwayMessage.message = "Unable to proceed. Please check the details before submitting!";
      }
    },
    selectAllToggle(props) {
      console.log(props)
       if(this.formData.Lists.length != this.itemList.length - this.disabledCount) {
         this.formData.Lists = [];
         const self = this;
         props.items.forEach(item => {
           if(!item.disabled) {
            // let temp = [];
            // item.MRData.forEach(item2 => {
            //   if(item2.rsdata){
            //     temp.push(item2);
            //   }
            // });
            // item.MRData_selected = temp;
            self.formData.Lists.push(item);
            self.expanded2.push(item)
           } 
         });
       } else this.formData.Lists = [];
     },
    getItemText(item) {
      return `${item.Brand ? item.Brand : 'No Brand'}` + ' | ' + `${item.Model ? item.Model : 'No Model'}` + ' | ' + `${item.SerialNo ? item.SerialNo : 'No Serial No.'}`;
    },
    getItemValue(item) {
      return item;
    },
    changeSerialNo(item){
      let temp = item.editableQty;
      item.editableQty = 0;
      item.editableQty = temp;
    }
  },
};
</script>
        
    <style scoped>
.v-dialog--fullscreen,
.poModal {
  overflow-x: hidden !important;
}
</style>